// import { browser, building, dev, version } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import { HeimdallService, MtmService } from '$lib/api';
import { persisted } from 'svelte-local-storage-store';
import { derived, get, writable, type Writable } from 'svelte/store';
import { goto } from '$app/navigation';
import { browser } from '$app/environment';
import type { Readable } from 'svelte/motion';
import { resetMeta } from '$stores/meta';
import { transformUrl, setLocale } from '$stores/master';
import dayjs from 'dayjs';

export type Auth = {
    userInfo: UserInfo;
    workspaceUserInfo?: WorkspaceUserInfo;
    workspace?: WorkspaceInfo & { subscriptionStatus?: MtmLauncherDto['subscriptionStatus'] };
    launcher?: MtmLauncherDto[];
    workspaceId?: number;
    tenantId?: number;
    workspaceName?: string;
    permissions: Array<HeimdallPermissionDto>;
    hasPermission: (permission: MtmPermissionDto['name']) => boolean;
    somePermissions: (permissionNames: Array<MtmPermissionDto['name']>) => boolean;
    products: MtmProductDto[];
    hasProduct: (productCode: MtmProductDto['type']) => boolean; // 해당 워크스페이스에 해당 제품이 있는지 확인
    hasProductRole: (productCode: MtmProductDto['type']) => boolean; // 해당 워크스페이스 해당 제품의 역할을 현재 사용자가 가지고 있는지 확인
};

export type UserInfo = Partial<MtmMyInfoDto>;
export type WorkspaceUserInfo = Partial<MtmMyWorkspaceInfoDto>;
export type WorkspaceInfo = Partial<MtmWorkspaceDto>;

// 유저 정보는 persisted로 localstorage에 자동 저장
export const userInfo: Writable<UserInfo> = persisted('userInfo', {});

//임시 코드, 기존 로그인 정보를 지우기 위함
if (browser) {
    window.localStorage.removeItem('auth');
}

// State
const initialValue: Partial<Auth> = {
    launcher: [],
    workspaceId: 0,
    tenantId: 0,
    permissions: [],
};

export const workspaceInfo: Writable<Partial<Auth>> = writable({ ...initialValue });
export const auth: Readable<Auth> = derived([workspaceInfo, userInfo], ([$workspaceInfo, $userInfo], set) => {
    const hasPermission = (permissionName: MtmPermissionDto['name']) => $workspaceInfo.permissions!.some(pm => pm.name === permissionName);
    const currentWorkspaceId = $workspaceInfo.workspaceId;
    const currentWorkspace = $workspaceInfo.launcher?.find(ws => ws.workspaceId === currentWorkspaceId);
    const currentWorkspaceInfo = currentWorkspace
        ? {
              id: currentWorkspace.workspaceId,
              memo: currentWorkspace.workspaceMemo,
              name: currentWorkspace.workspaceName,
              tenantId: currentWorkspace.tenantId,
              subscriptionStatus: currentWorkspace.subscriptionStatus,
          }
        : {};
    const currentWorkspaceUserInfo = currentWorkspace
        ? {
              name: currentWorkspace.displayName,
              id: currentWorkspace.userId,
              loginId: $userInfo.loginId,
              workspaceName: currentWorkspace.workspaceName,
              displayName: currentWorkspace.displayName,
              jobPositionName: currentWorkspace.jobPositionName,
              divisionName: currentWorkspace.divisionName,
              jobTitleName: currentWorkspace.jobTitleName,
          }
        : {};
    const currentWorkspaceProducts = currentWorkspace?.products || [];

    set({
        ...$workspaceInfo,
        userInfo: $userInfo,
        workspace: currentWorkspaceInfo,
        workspaceUserInfo: currentWorkspaceUserInfo,
        products: currentWorkspaceProducts,
        hasPermission,
        somePermissions: (permissionNames: Array<MtmPermissionDto['name']>) => permissionNames.some(permissionName => hasPermission(permissionName)),
        hasProduct: (productCode: MtmProductDto['type']) => {
            return currentWorkspaceProducts?.some(product => product.type === productCode);
        },
        hasProductRole: (productCode: MtmProductDto['type']) => {
            return currentWorkspaceProducts?.some(product => product.type === productCode && product.roles.length);
        },
    } as Auth);
});

export const session = derived(auth, $auth => {
    return !!$auth.userInfo?.id;
});

// 로그인
export const actionSignIn = async ({ loginId, password }: HeimdallLoginForm) => {
    try {
        await actionClearAuth();

        const res = await HeimdallService.login({
            requestBody: { loginId, password },
        });

        if (res.data.message) {
            alert(res.data.message);
            return false;
        }

        // 초기화
        workspaceInfo.set({
            ...initialValue,
        });

        await actionRefreshUserInfo();

        // await fetchCodes();
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);

        return false;
    }
    return true;
};

export const fetchRefreshWorkspaceInfo = async () => {
    await Promise.all([getLauncher(), actionRefreshPermissions()]);
};

// workspaceName을 이용하여 tenantId, workspaceId를 가져옴
export const extractWorkspaceInfo = (workspaceName: string) => {
    const [tenantId, workspaceId] = workspaceName.split('-').map(Number);
    return { tenantId: tenantId ?? 0, workspaceId: workspaceId ?? 0 };
};

// tenantId, workspaceId를 이용하여 workspaceName을 가져옴
export const makeWorkspaceName = (tenantId: number, workspaceId: number) => {
    return `${tenantId}-${workspaceId}`;
};

let cachedLauncher = [] as MtmLauncherDto[];
export const getLauncher = async () => {
    // luancher API 내부에는 각 워크스페이스에 대한 정보와 각 워크스페이스에서의 유저 정보가 포함되어 있음
    if (cachedLauncher.length > 0) return cachedLauncher;
    try {
        const {
            data: { data, code },
        } = await MtmService.getLauncher();
        if (code === 'SUCCESS') {
            cachedLauncher = data;
            workspaceInfo.update($auth => ({ ...$auth, launcher: cachedLauncher || [] }));
            return cachedLauncher;
        }
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
    }
    return [] as MtmLauncherDto[];
};

export const invalidateLauncher = async () => {
    cachedLauncher = [];
    await getLauncher();
};

// 사용자 권한 목록
let cachedPermissionsWorkspaceId = 0;
let cachedPermissions = [] as Array<HeimdallPermissionDto>;
export const actionRefreshPermissions = async () => {
    const workspaceId = get(auth).workspaceId;
    if (workspaceId !== cachedPermissionsWorkspaceId) {
        try {
            const res = await HeimdallService.listWorkspaceUserPermissionsV3();
            if (res.data.code === 'SUCCESS') {
                cachedPermissions = res.data.data || [];
                cachedPermissionsWorkspaceId = workspaceId ?? 0;
                workspaceInfo.update($workspaceInfo => ({ ...$workspaceInfo, permissions: cachedPermissions || [] }));
            }
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    }
};

// 사용자 정보 갱신
export const actionRefreshUserInfo = async (options: { ignoreErrorMessage: boolean } = { ignoreErrorMessage: false }) => {
    try {
        const myInfoRes = await MtmService.getMyInfo(options);
        if (myInfoRes.data.code === 'SUCCESS') {
            const myInfo = myInfoRes.data.data;
            userInfo.update($userInfo => ({ ...$userInfo, ...myInfo }));
            const userPreferredLocale = get(auth).userInfo.locale || '';
            await setLocale(userPreferredLocale);
        }
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
    }
};

// 로그아웃
export const signOut = async () => {
    try {
        await HeimdallService.logout();
    } catch (err) {
        console.error(err);
        Sentry.captureException(err);
    }
    await actionClearAuth();
    resetMeta();
    await setLocale();
};

// 로그아웃 후 페이지 이동
export const actionSignOut = (url?: string) => signOut().then(() => goto(transformUrl(url ?? '/sign-in'), { replaceState: true, invalidateAll: true }));

export const actionClearAuth = async () => {
    userInfo.set({});
    workspaceInfo.set({
        ...initialValue,
    });
    cachedPermissionsWorkspaceId = 0;
    cachedLauncher = [];
    cachedPermissions = [];
};

export async function setupAuthFromExternal() {
    try {
        await HeimdallService.getRefreshAfter({
            ignoreErrorMessage: true,
        }); // refreshAfter를 받아서 갱신
        if (!get(session)) {
            // 로그인 한 적은 없지만 세션이 있을 수 있는 경우(서브도메인 등에서 로그인한 경우)의 로그인 상태 유지를 위한 세션 갱신 시도
            await actionRefreshUserInfo({ ignoreErrorMessage: true });
        }
        const userPreferredLocale = get(auth).userInfo.locale || '';
        if (userPreferredLocale) await setLocale(userPreferredLocale);
    } catch (err) {
        // 갱신을 못한다고 해서, 에러는 아니므로 센트리는 생략
        console.log(err);
    }
}

let isRefreshing = false;

export function shouldRefreshToken() {
    if (!browser) return false; // 브라우저가 아니면 즉시 false 반환 (SSR에서는 리프레시 불필요

    const refreshTokenTiming = localStorage.getItem('refreshTokenTiming');
    if (!refreshTokenTiming) {
        return false; // 리프레시 타이밍 정보가 없으면 즉시 false 반환
    }

    const isLogin = get(session);
    if (!isLogin) {
        localStorage.removeItem('refreshTokenTiming'); // 로그인 상태가 아니면 리프레시 타이밍 정보를 삭제하고 false 반환
        return false;
    }

    const isTokenExpired = dayjs().isAfter(dayjs(refreshTokenTiming));
    const isTabActive = document.visibilityState === 'visible';

    return isTokenExpired && isTabActive && isLogin; // 토큰이 만료되었고, 탭이 활성 상태이고, 로그인 상태이면 true 반환
}

export async function refreshToken() {
    if (isRefreshing) return;
    isRefreshing = true;

    try {
        await HeimdallService.refreshV2();
    } catch (error) {
        actionClearAuth();
        resetMeta();
        Sentry.captureException(error);
        // await goto(transformUrl('/sign-in'), { replaceState: true });
    } finally {
        isRefreshing = false;
    }
}
