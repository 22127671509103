import { createServiceAxiosInstance } from './helper';
import * as AxiosServices from '#lib/api';

Object.values(AxiosServices).forEach(service => {
    if ('setupServiceInstance' in service) {
        service.setupServiceInstance(createServiceAxiosInstance);
    }
});

export * from '#lib/api';
