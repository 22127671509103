/* eslint-disable */
// https://dev2.bhsn.it/api/daredevil/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '../../utils';

let axiosInstance: AxiosInstance;

const serviceRoute = 'daredevil';
export function setupServiceInstance(callback: (serviceRoute: string) => AxiosInstance) {
    axiosInstance = callback(serviceRoute);
}

/**
 * Activity Feed 리스트 조회
 * @returns DareDevilResponseListActivityFeedDto OK
 * @throws ApiError
 */
export function getActivityFeeds(
    {
        litigationId,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListActivityFeedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/activity-feeds/v1',
                path: {
                    litigationId: litigationId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Activity Feed 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createActivityFeed(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
        requestBody: DareDevilActivityFeedCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/{litigationId}/activity-feeds/v1',
                path: {
                    litigationId: litigationId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Activity Feed 파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadActivityFeedFile(
    {
        litigationId,
        activityFeedId,
        fileKey,
    }: {
        /**
         * 사건 ID
         */
        litigationId: number;
        /**
         * 의견 및 히스토리 ID
         */
        activityFeedId: number;
        /**
         * 사건 첨부파일 키
         */
        fileKey: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/activity-feeds/{activityFeedId}/file/v1',
                path: {
                    litigationId: litigationId,
                    activityFeedId: activityFeedId,
                },
                query: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 대법원 사이트 연동
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function syncLawsuit(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: DareDevilLawsuitSyncForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/{litigationId}/lawsuit/sync/v1',
                path: {
                    litigationId: litigationId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사건 일반 내용 조회
 * @returns DareDevilResponseLawsuitDto OK
 * @throws ApiError
 */
export function getLawsuit(
    {
        litigationId,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseLawsuitDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/lawsuit/general',
                path: {
                    litigationId: litigationId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사건 진행 내용 목록 조회
 * @returns DareDevilResponseListLawsuitEventDto OK
 * @throws ApiError
 */
export function getLawsuitEvents(
    {
        litigationId,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListLawsuitEventDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/lawsuit/events/v1',
                path: {
                    litigationId: litigationId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 대법원 연동 화면에서 사용하는 코드 조회
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getLawsuitCodes(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/lawsuits/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * Health Check
 * @returns string OK
 * @throws ApiError
 */
export function hello(requestConfig: RequestConfig = {}): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/hello',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 비용 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateExpense(
    {
        id,
        litigationId,
        requestBody,
    }: {
        /**
         * expense 의 id
         */
        id: number;
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: DareDevilExpenseCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/litigations/{litigationId}/expenses/{id}/v1',
                path: {
                    id: id,
                    litigationId: litigationId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 디렉토리 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateDirectory(
    {
        litigationId,
        directoryId,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
        /**
         * directoryId 의 id
         */
        directoryId: number;
        requestBody: DareDevilLitigationDirectoryCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/litigations/{litigationId}/directories/{directoryId}/v1',
                path: {
                    litigationId: litigationId,
                    directoryId: directoryId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 디렉토리 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteDirectory(
    {
        litigationId,
        directoryId,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * directory의 id
         */
        directoryId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/litigations/{litigationId}/directories/{directoryId}/v1',
                path: {
                    litigationId: litigationId,
                    directoryId: directoryId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 파일 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateLitigationFileInDirectory(
    {
        litigationId,
        directoryId,
        fileId,
        requestBody,
    }: {
        /**
         * litigationId의 id
         */
        litigationId: number;
        /**
         * directory의 id
         */
        directoryId: number;
        /**
         * file의 id
         */
        fileId: number;
        requestBody: DareDevilLitigationEtcFileUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/litigations/{litigationId}/directories/{directoryId}/files/{fileId}/v1',
                path: {
                    litigationId: litigationId,
                    directoryId: directoryId,
                    fileId: fileId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 파일 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteLitigationFileInDirectory(
    {
        litigationId,
        directoryId,
        fileId,
    }: {
        /**
         * litigationId의 id
         */
        litigationId: number;
        /**
         * directory의 id
         */
        directoryId: number;
        /**
         * file의 id
         */
        fileId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/litigations/{litigationId}/directories/{directoryId}/files/{fileId}/v1',
                path: {
                    litigationId: litigationId,
                    directoryId: directoryId,
                    fileId: fileId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 상세 조회
 * @returns DareDevilResponseLitigationDto OK
 * @throws ApiError
 */
export function getLitigation(
    {
        id,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseLitigationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateLitigation(
    {
        id,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
        requestBody: DareDevilLitigationUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/litigations/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteLitigation(
    {
        id,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/litigations/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 완료 처리
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function completeLitigation(
    {
        id,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
        requestBody: DareDevilLitigationCompletionForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/litigations/{id}/completion/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 변호사 상세 조회
 * @returns DareDevilResponseLawyerDto OK
 * @throws ApiError
 */
export function getLawyer(
    {
        id,
    }: {
        /**
         * 변호사 의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseLawyerDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/lawyers/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 변호사 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateLawyer(
    {
        id,
        requestBody,
    }: {
        /**
         * 변호사 의 id
         */
        id: number;
        requestBody: DareDevilLawyerCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/lawyers/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로펌 상세 조회
 * @returns DareDevilResponseLawFirmDto OK
 * @throws ApiError
 */
export function getLawFirm(
    {
        id,
    }: {
        /**
         * 로펌의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseLawFirmDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/law-firms/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 로펌 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateLawFirm(
    {
        id,
        requestBody,
    }: {
        /**
         * 로펌의 id
         */
        id: number;
        requestBody: DareDevilLawFirmCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/law-firms/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 상세 조회
 * @returns DareDevilResponseConsultingDto OK
 * @throws ApiError
 */
export function getConsulting(
    {
        id,
    }: {
        /**
         * 자문의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseConsultingDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 수정
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function updateConsulting(
    {
        id,
        requestBody,
    }: {
        /**
         * consulting 의 id
         */
        id: number;
        requestBody: DareDevilConsultingUpdateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/consultings/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 담당자 변경
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function transferConsultingOwner(
    {
        id,
        requestBody,
    }: {
        /**
         * consulting 의 id
         */
        id: number;
        requestBody: DareDevilConsultingTransferOwnerForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/consultings/{id}/transfer-owner/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 리뷰 V2
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function reviewConsultingV2(
    {
        id,
        requestBody,
    }: {
        /**
         * 자문의 id
         */
        id: number;
        requestBody: DareDevilConsultingReviewForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/consultings/{id}/review/v2',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 리뷰
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function reviewConsulting(
    {
        id,
        requestBody,
    }: {
        /**
         * 자문의 id
         */
        id: number;
        requestBody: DareDevilConsultingReviewForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/consultings/{id}/review/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 비용 목록 조회
 * @returns DareDevilResponsePageLitigationExpenseDto OK
 * @throws ApiError
 */
export function getExpenses(
    {
        litigationId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 송무 ID
         */
        litigationId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageLitigationExpenseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/expenses/v1',
                path: {
                    litigationId: litigationId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 비용 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createExpense(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: DareDevilExpenseCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/{litigationId}/expenses/v1',
                path: {
                    litigationId: litigationId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 비용 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteExpenses(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
        requestBody: Array<number>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/litigations/{litigationId}/expenses/v1',
                path: {
                    litigationId: litigationId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 파일 조회
 * @returns DareDevilResponsePageFileDto OK
 * @throws ApiError
 */
export function getLitigationFilesInDirectory(
    {
        litigationId,
        directoryId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * litigationId 의 id
         */
        litigationId: number;
        /**
         * directoryId 의 id
         */
        directoryId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageFileDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/directories/{directoryId}/files/v1',
                path: {
                    litigationId: litigationId,
                    directoryId: directoryId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 파일 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createLitigationFileInDirectory(
    {
        litigationId,
        directoryId,
        requestBody,
    }: {
        /**
         * litigationId 의 id
         */
        litigationId: number;
        /**
         * directory id
         */
        directoryId: number;
        requestBody: DareDevilLitigationEtcFileCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/{litigationId}/directories/{directoryId}/files/v1',
                path: {
                    litigationId: litigationId,
                    directoryId: directoryId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 디렉토리 리스트 조회
 * @returns DareDevilResponseListLitigationDirectoryDto OK
 * @throws ApiError
 */
export function getLitigationDirectories(
    {
        litigationId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListLitigationDirectoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/directories/v1',
                path: {
                    litigationId: litigationId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 디렉토리 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createDirectory(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        requestBody: DareDevilLitigationDirectoryCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/{litigationId}/directories/v1',
                path: {
                    litigationId: litigationId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 파일 폴더 이동
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function moveFile(
    {
        litigationId,
        requestBody,
    }: {
        /**
         * litigationId의 id
         */
        litigationId: number;
        requestBody: DareDevilLitigationFileMoveForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/{litigationId}/directories/file-move/v1',
                path: {
                    litigationId: litigationId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 생성 V2 (return id)
 * @returns DareDevilResponseLong OK
 * @throws ApiError
 */
export function createLitigationV2(
    {
        requestBody,
    }: {
        requestBody: DareDevilLitigationCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseLong> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createLitigation(
    {
        requestBody,
    }: {
        requestBody: DareDevilLitigationCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/litigations/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 변호사 목록 조회
 * @returns DareDevilResponsePageLawyerVo OK
 * @throws ApiError
 */
export function getLawyers(
    {
        keyword,
        page,
        size = 10,
        sort,
    }: {
        keyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageLawyerVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/lawyers/v1',
                query: {
                    keyword: keyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 변호사 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createLawyer(
    {
        requestBody,
    }: {
        requestBody: DareDevilLawyerCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/lawyers/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 변호사 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteLawyers(
    {
        requestBody,
    }: {
        requestBody: Array<number>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/lawyers/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로펌 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createLawFirm(
    {
        requestBody,
    }: {
        requestBody: DareDevilLawFirmCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/law-firms/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로펌 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteLawFirms(
    {
        requestBody,
    }: {
        requestBody: Array<number>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/law-firms/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 워크플로우 생성 V2
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createConsultingWorkflowV2(
    {
        id,
        requestBody,
    }: {
        /**
         * 자문의 id
         */
        id: number;
        requestBody: DareDevilConsultingWorkflowCreateFormV2;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/{id}/workflows/v2',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 워크플로우 생성
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function createConsultingWorkflow(
    {
        id,
        requestBody,
    }: {
        /**
         * 자문의 id
         */
        id: number;
        requestBody: DareDevilConsultingWorkflowCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/{id}/workflows/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 재의뢰
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function reopenConsulting(
    {
        id,
    }: {
        /**
         * consulting 의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/{id}/reopen/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 워크플로우 액션 수행 V2
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function doActionConsultingWorkflowV2(
    {
        consultingId,
        workflowId,
        requestBody,
    }: {
        /**
         * 자문의 id
         */
        consultingId: number;
        /**
         * 자문 워크플로우의 id
         */
        workflowId: number;
        requestBody: DareDevilConsultingWorkflowActionForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/{consultingId}/workflows/{workflowId}/v2',
                path: {
                    consultingId: consultingId,
                    workflowId: workflowId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 워크플로우 액션 수행
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function doActionConsultingWorkflow(
    {
        consultingId,
        workflowId,
        requestBody,
    }: {
        /**
         * 자문의 id
         */
        consultingId: number;
        /**
         * 자문 워크플로우의 id
         */
        workflowId: number;
        requestBody: DareDevilConsultingWorkflowActionForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/{consultingId}/workflows/{workflowId}/v1',
                path: {
                    consultingId: consultingId,
                    workflowId: workflowId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 워크플로우 코멘트 추가
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function addCommentToConsulting(
    {
        consultingId,
        requestBody,
    }: {
        /**
         * 자문의 id
         */
        consultingId: number;
        requestBody: DareDevilConsultingWorkflowCommentFormV2;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/{consultingId}/comment/v1',
                path: {
                    consultingId: consultingId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 워크플로우 액션 일괄 수행
 * @returns DareDevilResponseListWorkflowStatusDto OK
 * @throws ApiError
 */
export function doActionConsultingWorkflows(
    {
        requestBody,
    }: {
        requestBody: DareDevilConsultingWorkflowActionsForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListWorkflowStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/workflows/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 생성
 * @returns DareDevilResponseLong OK
 * @throws ApiError
 */
export function createConsulting(
    {
        requestBody,
    }: {
        requestBody: DareDevilConsultingCreateForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseLong> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/consultings/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 스마트이메일 메시지 조회
 * @returns DareDevilResponseSmartEmailMessageDto OK
 * @throws ApiError
 */
export function getSmartEmailMessage(
    {
        litigationId,
        smartEmailMessageId,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * smartEmailMessage의 id
         */
        smartEmailMessageId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseSmartEmailMessageDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/smart-email-messages/{smartEmailMessageId}/v1',
                path: {
                    litigationId: litigationId,
                    smartEmailMessageId: smartEmailMessageId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 스마트이메일 첨부파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadSmartEmailFile(
    {
        litigationId,
        smartEmailMessageId,
        fileKey,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * smartEmailMessage의 id
         */
        smartEmailMessageId: number;
        /**
         * 스마트 이메일 첨부파일 키
         */
        fileKey: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/smart-email-messages/{smartEmailMessageId}/file/v1',
                path: {
                    litigationId: litigationId,
                    smartEmailMessageId: smartEmailMessageId,
                },
                query: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 스마트이메일 EML 파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadSmartEmailEmlFile(
    {
        litigationId,
        smartEmailMessageId,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * smartEmailMessage의 id
         */
        smartEmailMessageId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/smart-email-messages/{smartEmailMessageId}/eml-file/v1',
                path: {
                    litigationId: litigationId,
                    smartEmailMessageId: smartEmailMessageId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 스마트이메일 메시지 목록 조회
 * @returns DareDevilResponsePageSmartEmailMessageVo OK
 * @throws ApiError
 */
export function searchSmartEmailMessages(
    {
        litigationId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageSmartEmailMessageVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/smart-email-messages/v1',
                path: {
                    litigationId: litigationId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 스마트이메일 메시지 목록 조회 - Sync 포함
 * @returns DareDevilResponsePageSmartEmailMessageVo OK
 * @throws ApiError
 */
export function searchSmartEmailMessagesWithSync(
    {
        litigationId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageSmartEmailMessageVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/smart-email-messages/sync/v1',
                path: {
                    litigationId: litigationId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 스마트이메일 계정 주소 조회
 * @returns DareDevilResponseSmartEmailDto OK
 * @throws ApiError
 */
export function getSmartEmailAddress(
    {
        litigationId,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseSmartEmailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/smart-email-address/v1',
                path: {
                    litigationId: litigationId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 첨부파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadLitigationFile(
    {
        litigationId,
        fileKey,
    }: {
        /**
         * 사건 ID
         */
        litigationId: number;
        /**
         * 사건 파일 키
         */
        fileKey: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/file/v1',
                path: {
                    litigationId: litigationId,
                },
                query: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 비용 - 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function getExpenseExcel(
    {
        litigationId,
    }: {
        /**
         * litigation 의 id
         */
        litigationId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/expenses/excel/v1',
                path: {
                    litigationId: litigationId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadLitigationFileInDirectory(
    {
        litigationId,
        directoryId,
        fileKey,
    }: {
        /**
         * litigationId의 id
         */
        litigationId: number;
        /**
         * directory의 id
         */
        directoryId: number;
        /**
         * 사건 파일 키
         */
        fileKey: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/directories/{directoryId}/file/v1',
                path: {
                    litigationId: litigationId,
                    directoryId: directoryId,
                },
                query: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일관리 디렉토리 페이지 조회
 * @returns DareDevilResponsePageLitigationDirectoryDto OK
 * @throws ApiError
 */
export function getPageOfLitigationDirectories(
    {
        litigationId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * litigation의 id
         */
        litigationId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageLitigationDirectoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{litigationId}/directories/page/v1',
                path: {
                    litigationId: litigationId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 Header 조회
 * @returns DareDevilResponseLitigationHeaderDto OK
 * @throws ApiError
 */
export function getLitigationHeader(
    {
        id,
    }: {
        /**
         * litigation 의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseLitigationHeaderDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/{id}/header/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 검색 - 간단 송무 검색
 * @returns DareDevilResponsePageSimpleLitigationVo OK
 * @throws ApiError
 */
export function searchSimpleLitigation(
    {
        createdDateStart,
        createdDateEnd,
        litigationName,
        status,
        ownerIds,
        divisionIds,
        sortField,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 사건명
         */
        litigationName?: string;
        /**
         * 진행 상태 타입(related/v1:litigationStatus)
         */
        status?: 'IN_PROGRESS' | 'COMPLETED';
        /**
         * 담당자 목록
         */
        ownerIds?: Array<number>;
        /**
         * 의뢰 부서 목록
         */
        divisionIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageSimpleLitigationVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/simple-search/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    litigationName: litigationName,
                    status: status,
                    ownerIds: ownerIds,
                    divisionIds: divisionIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 검색
 * @returns DareDevilResponsePageLitigationVo OK
 * @throws ApiError
 */
export function searchLitigation(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        categories,
        ownerIds,
        statuses,
        divisionIds,
        sortField,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 사건 분류(codes/v1:litigationCategory)
         */
        categories?: Array<'CIVIL' | 'CRIMINAL' | 'ADMINISTRATIVE' | 'PATENT' | 'APPLICATION' | 'EXECUTION'>;
        /**
         * 담당자 목록
         */
        ownerIds?: Array<number>;
        /**
         * 상태(codes/v1:litigationStatus)
         */
        statuses?: Array<'IN_PROGRESS' | 'COMPLETED'>;
        /**
         * 의뢰 부서 목록
         */
        divisionIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageLitigationVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/search/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    categories: categories,
                    ownerIds: ownerIds,
                    statuses: statuses,
                    divisionIds: divisionIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 검색 - 이름으로 검색
 * @returns DareDevilResponseListLitigationNameVo OK
 * @throws ApiError
 */
export function searchLitigationWithName(
    {
        name,
    }: {
        name?: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListLitigationNameVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/name-search/v1',
                query: {
                    name: name,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 비용 생성/수정 화면에서 사용하는 코드 조회
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getLitigationExpenseCodes(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/expenses/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 검색 - 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function searchLitigationExcel(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        categories,
        ownerIds,
        statuses,
        divisionIds,
        sortField,
        sortDirection,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 사건 분류(codes/v1:litigationCategory)
         */
        categories?: Array<'CIVIL' | 'CRIMINAL' | 'ADMINISTRATIVE' | 'PATENT' | 'APPLICATION' | 'EXECUTION'>;
        /**
         * 담당자 목록
         */
        ownerIds?: Array<number>;
        /**
         * 상태(codes/v1:litigationStatus)
         */
        statuses?: Array<'IN_PROGRESS' | 'COMPLETED'>;
        /**
         * 의뢰 부서 목록
         */
        divisionIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/excel/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    categories: categories,
                    ownerIds: ownerIds,
                    statuses: statuses,
                    divisionIds: divisionIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 생성/수정 화면에서 사용하는 코드 조회
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getLitigationCodes(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/litigations/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로펌 변호사 목록 조회
 * @returns DareDevilResponsePageLawyerVo OK
 * @throws ApiError
 */
export function getLawyersInLawFirm(
    {
        id,
        lawyerName,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 로펌의 id
         */
        id: number;
        lawyerName?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageLawyerVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/law-firms/{id}/lawyers/v1',
                path: {
                    id: id,
                },
                query: {
                    lawyerName: lawyerName,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 간단 로펌 목록 조회
 * @returns DareDevilResponseListSimpleLawFirmVo OK
 * @throws ApiError
 */
export function getSimpleLawFirms(
    {
        name,
    }: {
        name?: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListSimpleLawFirmVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/law-firms/simple-search/v1',
                query: {
                    name: name,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 로펌 목록 조회
 * @returns DareDevilResponsePageLawFirmVo OK
 * @throws ApiError
 */
export function getLawFirms(
    {
        name,
        page,
        size = 10,
        sort,
    }: {
        name?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageLawFirmVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/law-firms/search/v1',
                query: {
                    name: name,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 로펌 생성/수정 화면에서 사용하는 코드 조회
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getLawFirmCodes(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/law-firms/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 리스트 검색
 * @returns DareDevilResponseListSimpleGroupDto OK
 * @throws ApiError
 */
export function searchGroups(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseListSimpleGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/search/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 그룹 리스트 검색
 * @returns DareDevilResponseListSimpleGroupDto OK
 * @throws ApiError
 */
export function searchMyGroups(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseListSimpleGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/search/my-groups/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 결재자 리스트 조회
 * @returns DareDevilResponseSetUserDivisionDto OK
 * @throws ApiError
 */
export function getDepartmentMembers(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseSetUserDivisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/department-members/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 업무 현황 조회
 * @returns DareDevilResponseDashboardTaskStatusDto OK
 * @throws ApiError
 */
export function getDashboardTaskStatus(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseDashboardTaskStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/dashboard/task-status/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 송무 TODO 검색
 * @returns DareDevilResponsePageLitigationVo OK
 * @throws ApiError
 */
export function searchLitigationToDo(
    {
        page,
        size = 10,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageLitigationVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/dashboard/litigations/todo-search/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 TODO 검색
 * @returns DareDevilResponsePageConsultingVo OK
 * @throws ApiError
 */
export function searchConsultingToDo(
    {
        page,
        size = 10,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageConsultingVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/dashboard/consultings/todo-search/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 캘린더 - 송무, 자문 일정 목록 조회
 * @returns DareDevilResponseCalendarScheduleDto OK
 * @throws ApiError
 */
export function getCalendarSchedules(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseCalendarScheduleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/dashboard/calendar/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 관련 권한 체크용 API
 * @returns DareDevilResponseBoolean OK
 * @throws ApiError
 */
export function checkUserRelatedToConsulting(
    {
        id,
    }: {
        /**
         * 자문의 id
         */
        id: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseBoolean> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/{id}/user-related/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 워크플로우 이벤트 조회
 * @returns DareDevilResponsePageConsultingWorkflowEventDto OK
 * @throws ApiError
 */
export function getConsultingWorkflowEvents(
    {
        consultingId,
        page,
        size = 20,
        sort,
    }: {
        /**
         * 자문의 id
         */
        consultingId: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageConsultingWorkflowEventDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/{consultingId}/workflows/events/v1',
                path: {
                    consultingId: consultingId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 첨부파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadConsultingFile(
    {
        consultingId,
        fileKey,
    }: {
        /**
         * 자문 id
         */
        consultingId: number;
        /**
         * 자문 파일 키
         */
        fileKey: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/{consultingId}/file/v1',
                path: {
                    consultingId: consultingId,
                },
                query: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 간단 자문 검색
 * @returns DareDevilResponsePageSimpleConsultingVo OK
 * @throws ApiError
 */
export function searchSimpleConsulting(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        displayStepCodes,
        ownerIds,
        sortField,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 디스플레이 진행 상태(codes/v1:displayStepCode)
         */
        displayStepCodes?: Array<'SAVED' | 'REVIEW_REQUEST' | 'REVIEWER_ASSIGNMENT' | 'REVIEW' | 'REVIEW_AGREEMENT' | 'APPROVED' | 'REJECTED' | 'NOT_ALLOWED'>;
        /**
         * 자문 의뢰자 목록
         */
        ownerIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageSimpleConsultingVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/simple-search/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    displayStepCodes: displayStepCodes,
                    ownerIds: ownerIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 검색
 * @returns DareDevilResponsePageConsultingVo OK
 * @throws ApiError
 */
export function searchConsulting(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        displayStepCodes,
        categories,
        reviewerIds,
        divisionIds,
        sortField,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 디스플레이 진행 상태(codes/v1:displayStepCode)
         */
        displayStepCodes?: Array<'SAVED' | 'REVIEW_REQUEST' | 'REVIEWER_ASSIGNMENT' | 'REVIEW' | 'REVIEW_AGREEMENT' | 'APPROVED' | 'REJECTED' | 'NOT_ALLOWED'>;
        /**
         * 자문 분류(codes/v1:consultingType)
         */
        categories?: Array<'CONTRACT' | 'EMPLOYMENT' | 'LABOR' | 'COMPLIANCE' | 'ESG' | 'TAX' | 'ADMINISTRATIVE' | 'IPR' | 'FAIR_TRADE' | 'SUBCONTRACTING' | 'INTERNATIONAL' | 'PRIVACY' | 'OTHER'>;
        /**
         * 자문 검토 목록
         */
        reviewerIds?: Array<number>;
        /**
         * 담당 부서 목록 (현재 미구현)
         */
        divisionIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageConsultingVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/search/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    displayStepCodes: displayStepCodes,
                    categories: categories,
                    reviewerIds: reviewerIds,
                    divisionIds: divisionIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns DareDevilResponsePageAssignmentVo OK
 * @throws ApiError
 */
export function searchAssignmentsV2(
    {
        condition,
        pageable,
    }: {
        condition: DareDevilAssignmentConditionV2;
        pageable: DareDevilPageable;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageAssignmentVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/search/assignments/v2',
                query: {
                    condition: condition,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 법무담당자에게 배정할 의뢰 검색
 * @returns DareDevilResponsePageAssignmentVo OK
 * @throws ApiError
 */
export function searchAssignments(
    {
        condition,
        pageable,
    }: {
        condition: DareDevilAssignmentCondition;
        pageable: DareDevilPageable;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageAssignmentVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/search/assignments/v1',
                query: {
                    condition: condition,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 검토 통계 검색
 * @returns DareDevilResponseListStatsByReviewDto OK
 * @throws ApiError
 */
export function searchStatsByReview(
    {
        createdDateStart,
        createdDateEnd,
        reviewerIds,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 자문 검토자 목록
         */
        reviewerIds?: Array<number>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListStatsByReviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/search-stats-by-review/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    reviewerIds: reviewerIds,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 검토자별 검토 진행 중인 자문 검색
 * @returns DareDevilResponseListReviewByReviewerVo OK
 * @throws ApiError
 */
export function searchReviewByReviewer(
    {
        reviewerId,
        reviewRole,
    }: {
        /**
         * 자문 검토자의 id
         */
        reviewerId: number;
        /**
         * 자문 검토 진행 상태
         */
        reviewRole?: 'REVIEWER' | 'REVIEW_AGREEMENT_APPROVER';
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListReviewByReviewerVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/search-review-by-reviewer/reviewers/{reviewerId}/v1',
                path: {
                    reviewerId: reviewerId,
                },
                query: {
                    reviewRole: reviewRole,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 이름으로 검색
 * @returns DareDevilResponseListConsultingNameVo OK
 * @throws ApiError
 */
export function searchConsultingWithName(
    {
        name,
    }: {
        name?: string;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponseListConsultingNameVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/name-search/v1',
                query: {
                    name: name,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 자문 검색
 * @returns DareDevilResponsePageConsultingVo OK
 * @throws ApiError
 */
export function searchConsultingInMyGroup(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        displayStepCodes,
        categories,
        reviewerIds,
        divisionIds,
        sortField,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 디스플레이 진행 상태(codes/v1:displayStepCode)
         */
        displayStepCodes?: Array<'SAVED' | 'REVIEW_REQUEST' | 'REVIEWER_ASSIGNMENT' | 'REVIEW' | 'REVIEW_AGREEMENT' | 'APPROVED' | 'REJECTED' | 'NOT_ALLOWED'>;
        /**
         * 자문 분류(codes/v1:consultingType)
         */
        categories?: Array<'CONTRACT' | 'EMPLOYMENT' | 'LABOR' | 'COMPLIANCE' | 'ESG' | 'TAX' | 'ADMINISTRATIVE' | 'IPR' | 'FAIR_TRADE' | 'SUBCONTRACTING' | 'INTERNATIONAL' | 'PRIVACY' | 'OTHER'>;
        /**
         * 자문 검토 목록
         */
        reviewerIds?: Array<number>;
        /**
         * 담당 부서 목록 (현재 미구현)
         */
        divisionIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponsePageConsultingVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/group-search/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    displayStepCodes: displayStepCodes,
                    categories: categories,
                    reviewerIds: reviewerIds,
                    divisionIds: divisionIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 검색 - 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function searchConsultingExcel(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        displayStepCodes,
        categories,
        reviewerIds,
        divisionIds,
        sortField,
        sortDirection,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 디스플레이 진행 상태(codes/v1:displayStepCode)
         */
        displayStepCodes?: Array<'SAVED' | 'REVIEW_REQUEST' | 'REVIEWER_ASSIGNMENT' | 'REVIEW' | 'REVIEW_AGREEMENT' | 'APPROVED' | 'REJECTED' | 'NOT_ALLOWED'>;
        /**
         * 자문 분류(codes/v1:consultingType)
         */
        categories?: Array<'CONTRACT' | 'EMPLOYMENT' | 'LABOR' | 'COMPLIANCE' | 'ESG' | 'TAX' | 'ADMINISTRATIVE' | 'IPR' | 'FAIR_TRADE' | 'SUBCONTRACTING' | 'INTERNATIONAL' | 'PRIVACY' | 'OTHER'>;
        /**
         * 자문 검토 목록
         */
        reviewerIds?: Array<number>;
        /**
         * 담당 부서 목록 (현재 미구현)
         */
        divisionIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/excel/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    displayStepCodes: displayStepCodes,
                    categories: categories,
                    reviewerIds: reviewerIds,
                    divisionIds: divisionIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 자문 검색 - 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function searchConsultingExcelInMyDivision(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        displayStepCodes,
        categories,
        reviewerIds,
        divisionIds,
        sortField,
        sortDirection,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 자문 디스플레이 진행 상태(codes/v1:displayStepCode)
         */
        displayStepCodes?: Array<'SAVED' | 'REVIEW_REQUEST' | 'REVIEWER_ASSIGNMENT' | 'REVIEW' | 'REVIEW_AGREEMENT' | 'APPROVED' | 'REJECTED' | 'NOT_ALLOWED'>;
        /**
         * 자문 분류(codes/v1:consultingType)
         */
        categories?: Array<'CONTRACT' | 'EMPLOYMENT' | 'LABOR' | 'COMPLIANCE' | 'ESG' | 'TAX' | 'ADMINISTRATIVE' | 'IPR' | 'FAIR_TRADE' | 'SUBCONTRACTING' | 'INTERNATIONAL' | 'PRIVACY' | 'OTHER'>;
        /**
         * 자문 검토 목록
         */
        reviewerIds?: Array<number>;
        /**
         * 담당 부서 목록 (현재 미구현)
         */
        divisionIds?: Array<number>;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT' | 'UPDATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/excel/my-division/v1',
                query: {
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    keyword: keyword,
                    displayStepCodes: displayStepCodes,
                    categories: categories,
                    reviewerIds: reviewerIds,
                    divisionIds: divisionIds,
                    sortField: sortField,
                    sortDirection: sortDirection,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 화면에서 사용하는 코드 조회
 * @returns DareDevilResponseMapStringListObject OK
 * @throws ApiError
 */
export function getConsultingCodes(requestConfig: RequestConfig = {}): AxiosPromise<DareDevilResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/consultings/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 삭제
 * @returns DareDevilResponse OK
 * @throws ApiError
 */
export function deleteConsulting(
    {
        consultingId,
    }: {
        /**
         * 자문의 id
         */
        consultingId: number;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<DareDevilResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/consultings/{consultingId}/v1',
                path: {
                    consultingId: consultingId,
                },
            }),
            requestConfig,
        ),
    );
}
